import { Link } from 'react-router-dom';

import '../styles/navbar.css';

const Navbar = () => {
  return (
    <div id='navbar'>
      <div className='inner'>
        <Link to='/'>
          <div className='menu'>Home</div>
        </Link>
        <Link to='/transcode'>
          <div className='menu'>Transcode</div>
        </Link>
        <Link to='/about'>
          <div className='menu'>About</div>
        </Link>
        <Link to='/privacy'>
          <div className='menu'>Privacy Policy</div>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
