import './styles/NotificationBar.css';

const NotificationBar = ({ type, text }) => {
  let backgroundColor;
  switch (type) {
    case 'news':
      backgroundColor = '#64bc26';
      break;
    case 'warning':
      backgroundColor = '#fad202';
      break;
    case 'alert':
      backgroundColor = '#ea1601';
      break;
    default:
      backgroundColor = 'black';
      break;
  }
  return (
    <>
      {type && text && (
        <div id='notification-bar'>
          <div id='notification-div' style={{ backgroundColor }}>
            {text}
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationBar;
