import { useEffect, useRef, useState } from 'react';
import { ffmpeg, fetchFile } from '../functions/ffmpeg';

import Navbar from '../components/Navbar';
import NotificationBar from '../components/NotificationBar';

import '../styles/transcode.css';

const Transcode = () => {
  const [inputFileObject, setInputFileObject] = useState(null);
  const [inputUrl, setInputUrl] = useState(
    localStorage.getItem('transcodeInputUrl')
  );
  const [outputUrl, setOutputUrl] = useState(
    localStorage.getItem('transcodeOutputUrl')
  );
  const [outputType, setOutputType] = useState(null);
  const [progress, setProgress] = useState(0);
  const [dropZoneColor, setDropZoneColor] = useState();

  const [notificationType, setNotificationType] = useState(
    window.notificationType
  );
  const [notificationText, setNotificationText] = useState(
    window.notificationText
  );

  const inputFileRef = useRef(null);

  useEffect(() => {
    if (inputFileObject) {
      changeVideo();
    }
  }, [inputFileObject]);

  useEffect(() => {
    localStorage.setItem('transcodeInputUrl', inputUrl);
    setProgress(0);
  }, [inputUrl]);

  useEffect(() => {
    localStorage.setItem('transcodeOutputUrl', outputUrl);
  }, [outputUrl]);

  useEffect(() => {
    document.title = 'Skrin | Transcode';
  }, []);

  function handleDrop(e) {
    // Avoid browser from opening file
    e.preventDefault();

    changeVideo(e.dataTransfer.files[0]);
    handleDragLeave(e);
  }

  function handleDragOver(e) {
    e.preventDefault();

    setDropZoneColor('gray');
  }

  function handleDragLeave(e) {
    e.preventDefault();

    setDropZoneColor('');
  }

  function handleFileInput() {
    inputFileRef.current.click();
  }

  function handleFileChange(e) {
    setInputFileObject(e.target.files[0]);
  }

  function changeVideo() {
    URL.revokeObjectURL(inputUrl);
    setInputUrl(URL.createObjectURL(inputFileObject));
  }

  async function commonMediaTranscode({ command, outputMime }) {
    if (inputFileObject) {
      showNotification({ type: 'news', text: 'Transcoding Started' });
    } else {
      showNotification({ type: 'warning', text: 'Please Choose File' });
      return;
    }

    handleProgress();

    const inputMime = inputFileObject.type;
    // const inputType = inputMime.split('/')[0];
    const inputExtension = inputMime.split('/')[1];

    const outputType = outputMime.split('/')[0];
    const outputExtension = outputMime.split('/')[1];

    const inputFile = `input.${inputExtension}`;
    const outputFile = `output.${outputExtension}`;

    ffmpeg.FS('writeFile', inputFile, await fetchFile(inputUrl));

    const commands = getCommand(`-i ${inputFile} ${command} ${outputFile}`);
    await ffmpeg.run(...commands);

    // Read result
    const data = ffmpeg.FS('readFile', outputFile);

    // Create a URL
    const outputBlob = new Blob([data.buffer], { type: outputMime });
    const blobUrl = URL.createObjectURL(outputBlob);
    setOutputUrl(blobUrl);
    setOutputType(outputType);
  }

  function removeAudio() {
    const command = `-c copy -an`;
    commonMediaTranscode({ command, outputMime: 'video/mp4' });
  }

  async function smallVideo() {
    const command = `-c:v libx264 -filter:v fps=23.976 -c:a libmp3lame -b:a 96k`;
    commonMediaTranscode({ command, outputMime: 'video/mp4' });
  }

  async function toMp3() {
    const command = `-c:a libmp3lame -b:a 96k`;
    commonMediaTranscode({ command, outputMime: 'audio/mp3' });
  }

  function handleProgress() {
    ffmpeg.setProgress(({ ratio: progress }) => {
      progress = Math.floor(progress * 100);
      setProgress(progress);

      if (progress === 100) {
        transcodingFinished();
      }
    });
  }

  function showNotification({ type, text }) {
    setNotificationType(type);
    setNotificationText(text);
    removeNotification();
  }

  function removeNotification() {
    setTimeout(() => {
      setNotificationType(null);
    }, 3000);
  }

  function transcodingFinished() {
    showNotification({ type: 'news', text: 'Transcoding Finished' });
  }

  function getCommand(command) {
    return command.split(' ');
  }

  async function handleDownload() {
    const fileName = 'skrin_' + Date.now().toString();

    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = outputUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
    }, 1000);
  }

  return (
    <>
      <Navbar />
      <div id='transcode-page'>
        <div className='video-div'>
          <video
            id='video-input'
            className='video-display'
            src={inputUrl}
            controls
          ></video>
          <b>Input</b>
        </div>

        <div id='progress-div'>
          <progress id='progress-bar' value={progress} max='100'></progress>
          <h3>{progress}%</h3>
        </div>

        {outputType && (
          <div className='video-div'>
            {outputType === 'video' ? (
              <video
                id='video-output'
                className='video-display'
                src={outputUrl}
                controls
              ></video>
            ) : (
              <audio src={outputUrl} controls></audio>
            )}
            <b>Output</b>
            <button className='button' onClick={handleDownload}>
              Download
            </button>
          </div>
        )}

        <input
          type='file'
          id='file-selector'
          accept='video/*'
          hidden
          ref={inputFileRef}
          onChange={handleFileChange}
        />

        <div
          className='drop-zone'
          style={{ backgroundColor: dropZoneColor }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={(e) => handleDrop(e)}
          onClick={handleFileInput}
        >
          <h1>Click or Drop File</h1>
        </div>

        <div id='option-div'>
          <button onClick={smallVideo}>Small Size(Very Slow)</button>
          <button onClick={toMp3}>To MP3</button>
          <button onClick={removeAudio}>Remove Audio</button>
        </div>
      </div>
      <NotificationBar type={notificationType} text={notificationText} />
    </>
  );
};

export default Transcode;
