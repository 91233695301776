import { useEffect } from 'react';
import { ffmpeg, fetchFile } from '../functions/ffmpeg';

import Navbar from '../components/Navbar';
import NotificationBar from '../components/NotificationBar';

import '../styles/home.css';

const Home = () => {
  useEffect(() => {
    // ffmpeg.setLogging(true);
    document.title = 'Skrin | Online Screen Recorder';
    // localStorage.setItem('recordedFile', blobUrl);

    const lightGreen = '#80ed99';
    const lightRed = '#fe5f55';
    const lightBlue = '#00b4d8';
    const buttonColor = 'rgb(206, 206, 206)';

    window.mainStream = new MediaStream();
    window.screenStream = new MediaStream();
    window.systemAudioStream = new MediaStream();
    window.micStream = new MediaStream();
    window.camStream = new MediaStream();
    window.combineAudioStream = new MediaStream();

    let mediaRecorder = null;
    let recordedBlobs = [];
    let videoBlob = null;
    let blobUrl = null;
    let filename;

    const codecList = [
      'video/webm;codecs=h264,opus',
      'video/webm;codecs=vp9,opus',
      'video/webm;codecs=vp8,opus',
    ];
    let codecPreferences;
    for (let i of codecList) {
      if (MediaRecorder.isTypeSupported(i)) {
        codecPreferences = i;
        break;
      }
    }

    const mimeType = codecPreferences.split(';', 1)[0]; // video/webm
    const extension = mimeType.split('/')[1]; // webm

    const textMessage = document.querySelector('.text-message');
    let blipInterval;

    const downloadButton = document.querySelector('#download-button');
    const previewButton = document.querySelector('#preview-button');
    const recordButton = document.querySelector('#record-button');

    const mainVideoElement = document.querySelector('#video-display');
    let camVideoElement = null;

    previewButton.addEventListener('click', async () => {
      try {
        resetButtons();
        removeAllTracks();
        removePictureInPicture();

        await handleSelectedVideoOption();
        await handleSelectedAudioOption();
        await displayLiveVideo();

        textMessage.textContent = 'Click "Start Recording"';
        previewButton.style.backgroundColor = lightGreen;
        recordButton.disabled = false;
      } catch (err) {
        alert('aa');
      }
    });

    recordButton.addEventListener('click', async () => {
      if (recordButton.textContent === 'Start Recording') {
        const string =
          'You will not able to download your previous recorded video if you continue';

        // Cancel recording
        if (videoBlob && !window.confirm(string)) {
          return;
        }

        resetBlobs();
        await startRecording();
      } else {
        stopRecording();
      }
    });

    downloadButton.addEventListener('click', async () => {
      downloadVideo();
    });

    async function displayLiveVideo() {
      mainVideoElement.srcObject = window.mainStream;
      mainVideoElement.controls = false;
      mainVideoElement.muted = true;

      try {
        mainVideoElement.onloadedmetadata = () => {
          mainVideoElement.play();
        };
      } catch (err) {
        handleError('Cannot play video');
        return;
      }
    }

    async function startRecording() {
      const mimeType = codecPreferences;
      const options = { mimeType };

      try {
        mediaRecorder = new MediaRecorder(window.mainStream, options);

        mediaRecorder.ondataavailable = handleDataAvailable;
        mediaRecorder.onstop = async () => {
          // // Stop all tracks from main stream
          removeAllTracks();
          removePictureInPicture();
          await afterStopRecording();
        };

        recordButton.style.backgroundColor = lightGreen;
        recordButton.textContent = 'Stop Recording';
        previewButton.disabled = true;

        await mediaRecorder.start();
      } catch (err) {
        handleError('Cannot Record');
        return;
      }

      // Animation
      const borderColors = ['red', 'gray'];
      let counter = 1;
      let hour, minute, second;
      textMessage.textContent = '00:00:00';
      blipInterval = setInterval(() => {
        const zeroPad = (number) => number.toString().padStart(2, '0');

        hour = zeroPad(Math.floor(counter / 3600));
        minute = zeroPad(Math.floor((counter / 60) % 60));
        second = zeroPad(counter % 60);
        const time = `${hour}:${minute}:${second}`;

        textMessage.textContent = time;
        mainVideoElement.style.borderColor = borderColors[counter % 2];
        counter++;
      }, 1000);
    }

    async function stopRecording() {
      await mediaRecorder.stop();
    }

    async function afterStopRecording() {
      resetButtons();

      filename = 'skrin_' + Date.now().toString();
      videoBlob = new Blob(recordedBlobs, { type: mimeType });

      clearInterval(blipInterval);
      await convertToMp4();
      await displayRecordedVideo();

      textMessage.textContent = 'Playing Recorded Video';
      mainVideoElement.style.borderColor = lightBlue;

      previewButton.disabled = false;
      previewButton.style.backgroundColor = buttonColor;

      downloadButton.disabled = false;
    }

    function removePictureInPicture() {
      // Exit PiP if available
      document.pictureInPictureElement && document.exitPictureInPicture();

      // Remove camVidElement if available
      // camVideoElement && document.body.removeChild(camVideoElement);
      camVideoElement && camVideoElement.remove();
    }

    async function displayRecordedVideo() {
      mainVideoElement.srcObject = null;
      mainVideoElement.src = blobUrl;
      mainVideoElement.controls = true;
      mainVideoElement.muted = false;

      try {
        await mainVideoElement.play();
      } catch (err) {
        handleError('Cannot play video');
      }
    }

    function handleDataAvailable(e) {
      if (e.data && e.data.size > 0) {
        recordedBlobs.push(e.data);
      }
    }

    async function showCamPictureInPicture() {
      camVideoElement = document.createElement('video');
      camVideoElement.hidden = true;
      document.body.appendChild(camVideoElement);
      camVideoElement.muted = true;
      camVideoElement.id = 'webcam-video';
      camVideoElement.srcObject = window.camStream;

      try {
        camVideoElement.addEventListener('leavepictureinpicture', async () => {
          removeTracksFromStream(window.camStream);
        });

        camVideoElement.addEventListener('loadedmetadata', async () => {
          camVideoElement.play();
          await camVideoElement.requestPictureInPicture();
        });
      } catch (err) {
        return;
      }
    }

    function downloadVideo() {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobUrl;
      // a.download = `${filename}.${extension}`;
      a.download = `${filename}.mp4`;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 1000);

      textMessage.textContent = 'Video Downloaded';
    }

    async function convertToMp4() {
      textMessage.textContent = 'Processing Video...';
      ffmpeg.FS(
        'writeFile',
        `${filename}.${extension}`,
        await fetchFile(videoBlob)
      );

      const input = `${filename}.${extension}`;
      const output = `${filename}.mp4`;
      await ffmpeg.run(
        '-i',
        input,
        '-codec:v',
        'copy',
        '-acodec',
        'aac',
        output
      );

      // Read result
      const data = ffmpeg.FS('readFile', output);

      // Create a URL form mp4
      videoBlob = new Blob([data.buffer], { type: 'video/mp4' });
      blobUrl = URL.createObjectURL(videoBlob);
    }

    async function handleSelectedVideoOption() {
      const element = document.querySelector('#choose-video');
      try {
        switch (element.value) {
          case 'screen-only':
            await getScreenAndSystemAudioStream();
            addTrackToMainStream(window.screenStream);
            break;
          case 'screen-camera':
            await getCamStream();
            await showCamPictureInPicture();

            await getScreenAndSystemAudioStream();
            addTrackToMainStream(window.screenStream);

            break;
          default:
            break;
        }
      } catch (err) {
        handleError('Cannot handle selected video option');
      }
    }

    async function handleSelectedAudioOption() {
      const element = document.querySelector('#choose-audio');
      try {
        switch (element.value) {
          case 'off-mic':
            await addTrackToMainStream(window.systemAudioStream);
            break;
          case 'on-mic':
            await getMicStream();

            // System Audio available
            if (window.systemAudioStream.getTracks().length) {
              // Merge system audio & mic audio
              const audioContext = new AudioContext();

              const audio1 = audioContext.createMediaStreamSource(
                window.systemAudioStream
              );
              const audio2 = audioContext.createMediaStreamSource(
                window.micStream
              );

              const destination = audioContext.createMediaStreamDestination();
              audio1.connect(destination);
              audio2.connect(destination);
              window.combineAudioStream = destination.stream;

              await addTrackToMainStream(window.combineAudioStream);

              // removeTracksFromStream(window.systemAudioStream);
              // removeTracksFromStream(window.micStream);
            } else {
              await addTrackToMainStream(window.micStream);
            }

            break;
          default:
            break;
        }
      } catch (err) {
        handleError('Cannot handle selected audio option');
      }
    }

    async function getScreenAndSystemAudioStream() {
      try {
        const screenAndSystemAudioStream =
          await window.navigator.mediaDevices.getDisplayMedia({
            video: {
              cursor: 'always',
            },
            audio: {
              echoCancellation: false,
              noiseSuppression: false,
              sampleRate: 9600,
              sampleSize: 24,
              channelCount: 2,
              volume: 1,
            },
          });

        // Seperate video stream & audio stream
        screenAndSystemAudioStream.getTracks().forEach((track) => {
          if (track.kind === 'video') {
            window.screenStream.addTrack(track);

            track.onended = async () => {
              mainVideoElement.srcObject = null;
              resetButtons();
              removeAllTracks();
              removePictureInPicture();
            };
          } else {
            window.systemAudioStream.addTrack(track);
          }
        });
      } catch (err) {
        previewButton.style.backgroundColor = lightRed;
        removeAllTracks();
        removePictureInPicture();
      }
    }

    async function getCamStream() {
      try {
        window.camStream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: 160,
            height: 90,
            // frameRate: 30,
          },
        });
      } catch (err) {
        handleError('Permission for using webcam has been declined');
      }
    }

    async function getMicStream() {
      try {
        window.micStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: false,
            noiseSuppression: false,
            sampleRate: 9600,
            sampleSize: 24,
            channelCount: 2,
            volume: 1,
          },
        });
      } catch (err) {
        handleError('Permission for using microphone has been declined');
      }
    }

    async function addTrackToMainStream(stream) {
      try {
        await stream.getTracks().forEach((track) => {
          window.mainStream.addTrack(track);
        });
      } catch (err) {
        return;
      }
    }

    async function removeTracksFromStream(stream) {
      try {
        const tracks = stream.getTracks();
        for (let i of tracks) {
          i.stop();
          stream.removeTrack(i);
        }
      } catch (err) {
        return;
      }
    }

    async function removeAllTracks() {
      removeTracksFromStream(window.screenStream);
      removeTracksFromStream(window.systemAudioStream);
      removeTracksFromStream(window.mainStream);
      removeTracksFromStream(window.camStream);
      removeTracksFromStream(window.combineAudioStream);
    }

    function resetButtons() {
      previewButton.disabled = false;
      previewButton.style.backgroundColor = buttonColor;

      recordButton.disabled = true;
      recordButton.style.backgroundColor = buttonColor;
      recordButton.textContent = 'Start Recording';

      downloadButton.disabled = true;
    }

    function resetBlobs() {
      recordedBlobs = [];
      videoBlob = null;
      blobUrl = null;
    }

    function handleError(message) {
      alert(message);
    }

    // // Detect mobile device
    if (/Mobi/.test(navigator.userAgent)) {
      mainVideoElement.style.width = '80%';
      mainVideoElement.style.height = '40%';
    }
  }, []);

  return (
    <>
      <Navbar />

      <div id='home-page'>
        <div className='main-div'>
          <div className='skrin-info'>
            <p>
              <b>Skrin</b> is a web-based screen recording that works 100% on
              your browser.
            </p>
          </div>

          <video id='video-display'></video>

          <div className='text-message'>Welcome</div>

          <h4>Click "Share audio" to get system audio</h4>
          <img src='./images/1.png' alt='system audio' />
        </div>

        <div className='controller-div'>
          <div className='buttons'>
            {/* Video */}
            <select name='choose-video' id='choose-video'>
              <option value='screen-only'>Screen Only</option>
              <option value='screen-camera'>Screen + Camera</option>
            </select>

            {/* Audio */}
            <select name='choose-audio' id='choose-audio'>
              <option value='off-mic'>Off Mic</option>
              <option value='on-mic'>On Mic</option>
            </select>

            <button id='preview-button'>Choose Screen</button>
            <button id='record-button' disabled>
              Start Recording
            </button>
            <button id='download-button' disabled>
              Download (mp4)
            </button>
          </div>
        </div>
      </div>

      <NotificationBar
        type={window.notificationType}
        text={window.notificationText}
      />
    </>
  );
};

export default Home;
