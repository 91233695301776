import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

const ffmpeg = createFFmpeg({
  corePath: '/ffmpeg/ffmpeg-core.js',
});

ffmpeg.setLogger(({ type, message }) => {
  if (type === 'info' && /ffmpeg-core loaded/.test(message)) {
    let event = new CustomEvent('ffmpegLoaded');
    window.dispatchEvent(event);
  }
});

export { ffmpeg, fetchFile };
