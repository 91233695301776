import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Privacy from './pages/Privacy';
import Transcode from './pages/Transcode';

import './styles/App.css';
import { ffmpeg } from './functions/ffmpeg';

function App() {
  // Detect mobile device
  if (/Mobi/.test(navigator.userAgent)) {
    const string = "We don't support mobile for now. Please use desktop.";
    window.notificationType = 'alert';
    window.notificationText = string;
  } else {
    registerServiceWorker();
    ffmpeg.load();
  }

  async function registerServiceWorker() {
    if (navigator.serviceWorker) {
      try {
        const registration = await navigator.serviceWorker.register('/sw.js');
        console.log('Service Worker registered');
      } catch (err) {
        console.log(`Error: ${err}`);
      }
    } else {
      console.log('Service worker not supported by your browser');
    }
  }

  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/transcode' component={Transcode} />
        <Route exact path='/about' component={About} />
        <Route exact path='/privacy' component={Privacy} />
        <Route path='*' component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
