import { useEffect } from 'react';

import Navbar from '../components/Navbar';

import '../styles/about.css';

const About = () => {
  useEffect(() => {
    document.title = 'Skrin | About';
  }, []);

  return (
    <>
      <Navbar />

      <div id='about-page'>
        <h1>About Skrin</h1>
        <h2>skrin.xyz</h2>
        <p>
          Skrin is a web-based screen recording that works 100% on your browser.
          This means you are not making any request from server <b>except</b>{' '}
          the initial loading of the web app. When we say it works 100% on your
          browser, it also means Skrin doesn't need any Internet connection to
          do the recording. Skrin powered by{' '}
          <a
            href='https://github.com/ffmpegwasm/ffmpeg.wasm'
            target='_blank'
            rel='noreferrer'
          >
            FFMPEG wasm
          </a>{' '}
          to do the transcoding from webm to mp4.
        </p>

        <h2>Developer</h2>
        <p>
          This web app developed by Adib Zaini (Me). I'm more interested in
          backend technology, but nowadays, many backend technology can be used
          on the client side. If you're interested with this project, you might
          want to look into my other projects too. All my projects are listed in
          my portfolio.
        </p>
        <p>
          Portfolio:{' '}
          <a href='https://adibzaini.xyz' target='_blank' rel='noreferrer'>
            adibzaini.xyz
          </a>
          <br />
          Github:{' '}
          <a
            href='https://github.com/adibzter'
            target='_blank'
            rel='noreferrer'
          >
            ADIBzTER
          </a>
          <br />
          Linkedin:{' '}
          <a
            href='https://linkedin.com/in/adib-zaini'
            target='_blank'
            rel='noreferrer'
          >
            Adib Zaini
          </a>
          <br />
          Twitter:{' '}
          <a
            href='https://twitter.com/adibzter'
            target='_blank'
            rel='noreferrer'
          >
            @adibzter
          </a>
          <br />
          Email:{' '}
          <a href='mailto:muhammadadibzaini@gmail.com'>
            muhammadadibzaini@gmail.com
          </a>
        </p>
      </div>
    </>
  );
};

export default About;
